/*
  Small standalone script to fire DOMReady event when the page is loaded
  This should only be used on no-vue pages that do not use the main emr-v3 app

  Currently used on:
    - Client Portal Authentication Layout
    - Client Portal Layout
*/
/* explicitly import shared packages to guarantee definition for V1 */
import '~shared/Axios'
import '~shared/CSRFTokens'
import '~shared/Microtasks'
import '~shared/Ready'
import '~shared/Logging'
import '~shared/Websockets'
// import { scaleKipuPage } from '@/lib/utils/PageScaling'

pageLoaded(() => {
  // scaleKipuPage()

  console.debug('FIRE DOM READY')
  fireDOMReady()
})
